import { lazy, Suspense } from "react";

const Quiz = lazy(() => import("../Quiz"));

import "./App.scss";

const App = () => {


  return (
    <div class="app">
      <Suspense fallback={<div>Loading...</div>}>
        <Quiz /> 
      </Suspense>
    </div>
  );
};

export default App;
